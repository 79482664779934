import { useCallback, useEffect, useRef, useState } from 'react';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import type Dropzone from 'dropzone';
import { useOnUnmount } from 'Hooks/useOnUnmount';
import { useTranslator } from 'Providers/Translator';
import { useForm } from '../Components/Form/useForm';

type DropzoneOptions = Dropzone.DropzoneOptions;
type DropzoneFile = Dropzone.DropzoneFile;
export interface SuccessResponse {
  data?: {
    id: string;
    type: string;
    attributes: {
      file_name: string;
      file_url: string;
    };
  };
}

interface ErrorResponse {
  errors?: Array<{
    detail: string;
    status: number;
    title: string;
  }>;
}

export interface DropzoneConfig extends DropzoneOptions {
  /* The full URL to which the POST request can be send */
  url: DropzoneOptions['url'];
  /** All data that is appended to the file upload request */
  append?: {
    [t: string]: any;
  };
  /** Function executed before first upload */
  onBeforeUpload?: (files?: DropzoneFile[]) => void | Promise<void>;
  /** Function executed after queue has been completely uploaded */
  onAfterUpload?: (files?: DropzoneFile[]) => Promise<void> | undefined;
  /** Function executed just before each file upload, which can modify the XMLHttpRequest */
  onFileUpload?: (
    file: DropzoneFile,
    xhr: XMLHttpRequest,
    formData: FormData
  ) => void;
  /** Function executed after each successful file upload */
  onAfterFileUpload?: (file: DropzoneFile, response: SuccessResponse) => void;
  /** Function executed after each failed upload */
  onUploadError?: (file: DropzoneFile, response: ErrorResponse) => void;
  /** Function executed on file remove handling */
  onRemoveFile?: () => void | Promise<void>;
  /** Only start uploading when submitting the form the input upload is in */
  uploadOnSubmit?: boolean;
}

/**
 * A hook for initializing a DropzoneJS component with the different event handlers
 */
export function useDropzoneUpload({
  onBeforeUpload,
  onAfterUpload,
  onAfterFileUpload,
  onFileUpload,
  onUploadError,
  uploadOnSubmit = false,
  append,
  headers,
  ...options
}: DropzoneConfig) {
  const { url } = options;
  const container = useRef<HTMLDivElement>(null);
  const dropContainer = useRef<HTMLDivElement>(null);

  const { capture, acceptedFiles } = options;
  const [dropZone, setDropZone] = useState<Dropzone>();
  const [t] = useTranslator();

  const unloaded = useRef(false);
  useEffect(() => {
    (async () => {
      unloaded.current = true;
      const DropzoneClass = (await import('dropzone')).default;
      DropzoneClass.autoDiscover = false;
      if (
        container.current &&
        dropContainer.current &&
        !dropContainer.current.dropzone &&
        unloaded.current
      ) {
        try {
          setDropZone(
            new DropzoneClass(dropContainer.current, {
              ...options,
              clickable: [container.current],
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    })();
    return () => {
      unloaded.current = false;
    };
  }, [container, dropContainer, options, dropZone]);

  const { headers: apiHeaders } = useApi();

  const priorityMimeType = capture
    ? {
        camcorder: 'video/*',
        camera: 'image/*',
        microphone: 'audio/*',
      }[capture]
    : undefined;
  useEffect(() => {
    if (dropZone?.options) {
      dropZone.options = {
        ...dropZone.options,
        ...{
          chunking: !!options.chunkSize,
          acceptedFiles:
            priorityMimeType ||
            (acceptedFiles === '*' ? undefined : acceptedFiles),
          autoProcessQueue: false,
          parallelChunkUploads: false,
          withCredentials: true,
          timeout: 240000,
          clickable: [container.current!],
          headers: {
            ...(apiHeaders as { [key: string]: string }),
            ...headers,
          },
          ...options,
        },
      };
    }
  }, [
    dropZone,
    priorityMimeType,
    acceptedFiles,
    capture,
    options,
    apiHeaders,
    headers,
    url,
  ]);

  const [tryAgain, setTryAgain] = useState(false);
  const processQueue = useCallback(() => {
    if (
      dropContainer.current &&
      !(dropContainer.current?.dropzone?.options?.url as string).includes(
        'undefined'
      )
    ) {
      dropContainer.current.dropzone.processQueue();
    } else {
      setTryAgain(true);
    }
  }, [dropContainer]);

  useEffect(() => {
    const dropzoneObject = dropContainer.current?.dropzone;
    if (
      tryAgain &&
      dropzoneObject &&
      typeof url === 'string' &&
      !url.includes('undefined')
    ) {
      dropzoneObject.options.url = url;
      dropzoneObject.processQueue();
    }
  }, [tryAgain, url]);

  const { subscribe } = useForm(true) || {};
  useEffect(() => {
    if (subscribe && uploadOnSubmit) {
      return subscribe('submit', () => {
        processQueue();
      });
    }
  }, [subscribe, processQueue, uploadOnSubmit]);

  const handleSending = useCallback(
    (file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => {
      if (!formData.has('name')) {
        formData.append('name', file.name);
      }
      if (!formData.has('filename')) {
        formData.append('filename', file.name);
      }
      if (append) {
        Object.keys(append).forEach((key) => {
          if (!formData.has(key)) {
            formData.append(key, append[key]);
          }
        });
      }
      onFileUpload?.(file, xhr, formData);
    },
    [onFileUpload, append]
  );

  const handleAddedFile = useCallback(
    async (files: DropzoneFile[]) => {
      if (onBeforeUpload) {
        await onBeforeUpload(files);
      }
      if (!uploadOnSubmit) {
        processQueue();
      }
    },
    [processQueue, onBeforeUpload, uploadOnSubmit]
  );

  const handleFileUploaded = useCallback(
    async (file: DropzoneFile, response: SuccessResponse) => {
      if (onAfterFileUpload) {
        await onAfterFileUpload(file, response);
      }

      if (dropContainer.current) dropContainer.current.dropzone.processQueue();
    },
    [onAfterFileUpload]
  );

  useOnUnmount(() => {
    if (dropZone) {
      dropZone?.destroy();
    }
  }, [dropZone]);

  const handleUploadError = useCallback(
    async (file: DropzoneFile, response: ErrorResponse) => {
      const errorStatus = file.xhr?.status;

      const errorBox = file.previewElement.querySelectorAll<HTMLSpanElement>(
        '[data-dz-errormessage]'
      )[0];
      if (errorBox) {
        errorBox.innerHTML =
          {
            422:
              response?.errors?.map((error) => error.detail).join('<br />') ||
              '',
            413: t('validation.to_large'),
          }[errorStatus || 0] || t('validation.unspecified');
      }
      await onUploadError?.(file, response);
    },
    [onUploadError, t]
  );

  const handleQueueComplete = useCallback(async () => {
    if (onAfterUpload) {
      await onAfterUpload(dropZone?.getAcceptedFiles());
      dropZone?.getAcceptedFiles().forEach((file) => {
        if (file.status === 'error') {
          setTimeout(() => dropZone.removeFile(file), 5000);
        } else {
          dropZone.removeFile(file);
        }
      });
    }
  }, [dropZone, onAfterUpload]);

  useEffect(() => {
    if (dropZone?.on) {
      dropZone.on('sending', handleSending);
      return () => {
        dropZone.off('sending', handleSending);
      };
    }
  }, [dropZone, handleSending]);

  useEffect(() => {
    if (dropZone?.on) {
      dropZone.on('addedfiles', handleAddedFile);
      dropZone.on('success', handleFileUploaded);
      dropZone.on('error', handleUploadError);
      dropZone.on('queuecomplete', handleQueueComplete);
      return () => {
        dropZone.off('addedfiles', handleAddedFile);
        dropZone.off('success', handleFileUploaded);
        dropZone.off('error', handleUploadError);
        dropZone.off('queuecomplete', handleQueueComplete);
      };
    }
  }, [
    dropZone,
    handleAddedFile,
    handleQueueComplete,
    handleFileUploaded,
    handleUploadError,
  ]);

  return {
    dropZone,
    dropContainer,
    container,
  };
}
