import React, { FC, PropsWithChildren } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import {
  DeleteForever,
  Description,
  GetApp,
  Replay,
  Visibility,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import dynamic from 'next/dynamic';
import { useModals } from 'Providers/ModalProvider/useModals';
import {
  isImage,
  isPDFDocument,
  isVideo,
  isWordDocument,
} from '../../../../Utils/getFileType';
import { IHTMLBaseProperties } from '../../Types/ElementTypes';
import styles from './FileCard.module.css';

const cx = classNames.bind(styles);

const FilePreviewModal = dynamic(
  () => import('Modals/PreviewFileModal/PreviewFileModal')
) as FC<any>;

export interface FileCardProps extends IHTMLBaseProperties {
  /** Adds a remove button which calls this method to remove the file from server */
  onRemove?: () => void | Promise<unknown>;
  /** Adds a retry button which calls this method to retry the upload */
  onRetry?: () => Promise<void> | void;
  /** Size of the file in a human-readable string */
  fileSize?: string;
  /** Date of the file in a human-readable string */
  fileDate?: string;
  /** Image to show as a thumbnail with the file */
  thumbnail?: string;
  /** The amount of progress that has been made */
  progress?: number;
  /** When uploading, show all divs to work with DropzoneJS */
  uploading?: boolean;
  /** Provide a secondary URL to preview the file */
  previewUrl?: string;
  /** Shows an error message when uploading failed */
  error?: string;
  /** A download url to a specific file */
  href?: string;
  /** Hides the preview container completely */
  hidden?: boolean;
  /** The mimetype of the file */
  mimeType?: string;
}

/**
 * FileCard.
 *
 * Works with DropzoneJS to render an uploading file.
 *
 * For example, you can `ReactDOM.renderToStaticMarkup(<FileCard />)`
 */
export const FileCard = ({
  error,
  progress,
  thumbnail,
  children,
  fileSize,
  fileDate,
  onRemove,
  onRetry,
  href,
  previewUrl,
  mimeType,
  hidden,
  uploading,
  className,
  style,
}: PropsWithChildren<FileCardProps>) => {
  const isImageThumbnail = isImage(thumbnail) || (isImage(mimeType) && !!href);
  const { openModal } = useModals(true) || {};
  const isPreviewable =
    !!openModal &&
    (isWordDocument(mimeType) ||
      isPDFDocument(mimeType) ||
      isImage(mimeType) ||
      isVideo(mimeType));

  const CardOutput = (
    <div
      className={cx(
        styles.base,
        {
          isError: !!error,
          isSuccess: progress === 100 && !error,
          isHidden: hidden,
        },
        'dz-preview',
        'dz-file-preview',
        'ui-upload__preview-container',
        className
      )}
      style={style}
    >
      <div className={cx(styles.details, 'ui-upload__preview-details')}>
        <div>
          <div className={cx(styles.thumbnail, 'ui-upload__preview-thumbnail')}>
            {isImageThumbnail ? (
              <img
                src={thumbnail}
                className={cx(styles.thumbnailImage)}
                data-dz-thumbnail="true"
                alt=""
              />
            ) : (
              <div className={cx(styles.thumbnailIcon)}>
                <Description fontSize="large" />
              </div>
            )}
          </div>
        </div>

        <div className={cx(styles.content)}>
          <div className={cx(styles.top)}>
            <div className={cx(styles.name)} data-dz-name>
              {children}
            </div>
            {![undefined, 100].includes(progress) && (
              <div className={cx(styles.percentage)}>
                {progress && `${progress}%`}
              </div>
            )}
          </div>

          <div className={cx(styles.bottom)}>
            {uploading || (progress && progress !== 100) ? (
              <div
                className={cx(styles.progress, 'ui-upload__preview-progress')}
              >
                <span
                  className={cx(styles.innerProgress)}
                  data-dz-uploadprogress
                  style={progress ? { width: `${progress}%` } : undefined}
                />
              </div>
            ) : uploading || error ? (
              <div className={cx(styles.errorMessage, 'dz-error-message')}>
                <span data-dz-errormessage>{error}</span>
              </div>
            ) : (
              <div className={cx(styles.metadata)}>
                <span data-dz-size>{fileSize}</span>
                {fileDate && <span>{fileDate}</span>}
              </div>
            )}
          </div>
        </div>

        {isPreviewable ? (
          <Button
            type="button"
            small
            quiet
            onClick={() =>
              openModal(FilePreviewModal, {
                title: children,
                mimeType,
                url: href,
                previewUrl,
              })
            }
          >
            <Visibility fontSize="medium" />
          </Button>
        ) : (
          <>
            {href && (
              <GetApp
                fontSize="medium"
                className={cx(styles['download-icon'])}
              />
            )}
          </>
        )}

        {error && onRetry && (
          <Button
            type="button"
            small
            quiet
            className={cx(styles.retry)}
            onClick={onRetry}
          >
            <Replay fontSize="medium" />
          </Button>
        )}

        {!uploading && onRemove && (
          <div data-dz-remove>
            <Button
              type="button"
              small
              quiet
              className={cx(styles.removeFile)}
              onClick={onRemove}
            >
              <DeleteForever color="error" fontSize="medium" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return !isPreviewable && href ? (
    <Link
      target="_blank"
      download={!isVideo(mimeType)}
      className={cx(styles.link)}
      href={href}
    >
      {CardOutput}
    </Link>
  ) : (
    CardOutput
  );
};
